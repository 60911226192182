import request from '@/utils/request'
import { getToken, getType } from "@/utils/auths";

// export function userLogin(params) {
//     return request({
//         url: '/henan/user/userdeng/login',
//         method: 'post',
//         params
//     })
// }
// 用户信息
export function getUserInfo(data) {
    var token = getToken("token");
    var type = getType('type')
    return request({
        url: '/sys/user/info',
        method: 'get',
        headers: { "Content-Type": "application/json", 'token': token, 'type': type },
        params: data
    })
}