<template>
  <div id="app">
    <router-view />
    <!-- <HelloWorld :msg="msg" :user="user" /> -->
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld
  },
  data(){
    return{
      msg: 'Welcome to Your Vue.js App',
      user: {
        id: 1,
        name: '123',
        gender: '男'
      }
    }
  },
  mounted(){
    console.log(this)
  },
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
</style>
