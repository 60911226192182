import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auths'
import config from "./syncConfig.js";

// create an axios instance
const service = axios.create({
    // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    // baseURL: 'https://henan.jybigdata.com/henanwebapi',
    baseURL: config.infoUrl,
    timeout: 20000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent

        if (store.getters.token) {
            config.headers.common['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        return config
    },
    error => {
        // do something with request error
        //console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data
            //console.log('res', res)
            // // if the custom code is not 20000, it is judged as an error.
            // if (res.status !== 200) {
            //   Message({
            //     message: res.message || 'Error',
            //     type: 'error',
            //     duration: 5 * 1000
            //   })

        //   return Promise.reject(new Error(res || 'Error'))
        // } else {
        //   return Promise.resolve(res)
        // }

        if (res.code == 2) {
            Message({
                message: res.message,
                type: 'error',
                duration: 3 * 1000
            })
        }

        return Promise.resolve(res)
    },
    error => {
        //console.log('error.response', error.response)
        //console.log('error', error)

        if (error.response.code == 2) {
            Message({
                message: error.response.message,
                type: 'error',
                duration: 3 * 1000
            })
        } else {
            Message({
                message: '服务器出错',
                type: 'error',
                duration: 3 * 1000
            })
        }

        return Promise.reject(error)
    }
)

export default service