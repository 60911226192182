import Cookies from "js-cookie";

const TokenKey = "token";
const Type = 'type';
export function getToken() {
    return Cookies.get(TokenKey);
}

export function setToken(token) {
    return Cookies.set(TokenKey, token);
}

export function removeToken() {
    return Cookies.remove(TokenKey);
}
export function getType() {
    return Cookies.get(Type);
}

export function setType(type) {
    return Cookies.set(Type, '2');
}

export function removeType() {
    return Cookies.remove(Type);
}