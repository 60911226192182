import router from '@/router'
import Router from 'vue-router'

const token = 'token',
    typeId = 'typeId',
    vid = 'vid',
    selectVid = 'selectVid',
    isPlay = 'isPlay',
    FruitsKey = 'choosenFruits',
    isAutoTurn = 'isAutoTurn',
    currentType = 'currentType'

export function getToken() {
    return localStorage.getItem(token)
}
export function setToken(obj) {
    return localStorage.setItem(token, obj)
}

export function removeToken() {
    return localStorage.removeItem(token)
}

export function getTypeId() {
    return localStorage.getItem(typeId)
}

export function setTypeId(obj) {
    return localStorage.setItem(typeId, obj)
}

export function removeTypeId() {
    return localStorage.removeItem(typeId)
}

export function getSelectVid() {
    return localStorage.getItem(selectVid)
}

export function setSelectVid(value) {
    return localStorage.setItem(selectVid, value)
}

export function removeSelectVid() {
    return localStorage.removeItem(selectVid)
}

export function getVid() {
    return localStorage.getItem(vid)
}

export function setVid(obj) {
    return localStorage.setItem(vid, obj)
}

export function removeVid() {
    return localStorage.removeItem(vid)
}

export function getFruitsInfor() {
    //console.log('FruitsKey', FruitsKey)
    return localStorage.getItem(FruitsKey)
}

export function setFruitsInfor(obj) {
    //console.log('objjj', obj)
    return localStorage.setItem(FruitsKey, obj)
}

export function removeFruitsInfor() {
    return localStorage.removeItem(FruitsKey)
}

export function getIsPlay() {
    return localStorage.getItem(isPlay)
}

export function setIsPlay(obj) {
    return localStorage.setItem(isPlay, obj)
}

export function removeIsPlay() {
    return localStorage.removeItem(isPlay)
}

export function setIsAutoTurn(obj) {
    return localStorage.setItem(isAutoTurn, obj)
}

export function getIsAutoTurn(obj) {
    return localStorage.getItem(isAutoTurn)
}

export function setCurrentType(obj) {
    return localStorage.setItem(currentType, obj)
}

export function getCurrentType(obj) {
    return localStorage.getItem(currentType)
}

export function clearRouter() {
    let newRouter = new Router({
        routes: []
    });
    //console.log('router.matcher', router.matcher)
    router.matcher = newRouter.matcher
}