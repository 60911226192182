/**
 * 获取登录的接口地址
 */
let config
let syncConfigData = function(url) {
  let xhr = new XMLHttpRequest()
  xhr.open("get", url, false)
  xhr.send()
  return JSON.parse(xhr.responseText)
}
config = syncConfigData("./../js/config.json?r=" + Math.random())
export default config