import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Index',
    redirect: "province",
    component: () =>
        import ('@/views/Index'),
    children: [{
            path: '/platform',
            name: 'platform',
            component: () =>
                import ('@/views/platform/index')
        },
        {
            path: '/province',
            name: 'province',
            component: () =>
                import ('@/views/province/index')
        },
        {
            path: '/countrywide',
            name: 'countrywide',
            component: () =>
                import ('@/views/countrywide/index')
        }
    ]
}]

export default new VueRouter({
    routes
})