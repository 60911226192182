import { userLogin } from '@/api/user'
import { getToken, setToken, getTypeId, setTypeId, getSelectVid, setSelectVid, getVid, setVid, getFruitsInfor, setFruitsInfor, getIsPlay, setIsPlay } from '@/utils/auth'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    fruit: getFruitsInfor(),
    token: getToken(),
    typeId: getTypeId(),
    vid: getVid(),
    selectVid: getSelectVid(),
    isPlay: getIsPlay()
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_TYPEID: (state, id) => {
      state.typeId = id
    },
    SET_VID: (state, vid) => {
      state.vid = vid
    },
    SET_SELECTVID: (state, vid) => {
      state.selectVid = vid
    },
    SET_FRUIT: (state, obj) => {
      state.fruit = obj
    },
    SET_ISPLAY: (state, value) => {
      state.isPlay = value
    }
  },
  actions: {
    login({ commit }, userAccount) {
      let { account, password } = userAccount
      return new Promise((resolve, reject) => {
        userLogin({ account: account, password: password }).then(response => {
          //console.log('respi', response)
          setToken(response.data.token)
          commit('SET_TOKEN', response.data.token)
          setTypeId(response.data.typeId)
          commit('SET_TYPEID', response.data.typeId)
          setVid(response.data.vid)
          commit('SET_VID', response.data.vid)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    fruit({ commit }, fruitIntro) {
      //console.log('fruitIntroxxxx', fruitIntro)
      setFruitsInfor(fruitIntro)
      commit('SET_FRUIT', fruitIntro)
    },
    selectVid({ commit }, selectVid) {
      //console.log('selectVid', selectVid)
      setSelectVid(selectVid)
      commit('SET_SELECTVID', selectVid)
    },
    play({ commit }, value) {
      //console.log('playxxxx', value)
      //console.log('typeof playxxxx', typeof value)
      return new Promise((resolve, reject) => {
        setIsPlay(value+'')
        commit('SET_ISPLAY', value+'')
        resolve()
      })
    }
  }
})
